<template>
	<div class="dataTotal">
		<a-row type="flex" align="middle">
			<a-col>选择日期：</a-col>
			<a-col>
				<a-range-picker v-model="time" />
				<a-button
					type="primary"
					style="margin-left: 16px"
					@click="submit"
				>
					查询
				</a-button>
			</a-col>
		</a-row>
		<div class="chart" ref="chartDom"></div>
	</div>
</template>

<script>
	import * as echarts from "echarts";
	export default {
		name: "dataTotal",
		data() {
			return {
				time: [],
				chart: null,
				option: {
					title: {
						text: ""
					},
					tooltip: {
						trigger: "axis"
					},
					legend: {
						data: ["uv", "pv"]
					},
					grid: {
						left: "3%",
						right: "4%",
						bottom: "3%",
						containLabel: true
					},
					xAxis: {
						type: "category",
						boundaryGap: false,
						data: []
					},
					yAxis: {
						type: "value"
					},
					series: []
				}
			};
		},
		created() {
			this.initTime();
		},
		mounted() {
			this.getList();
			this.initChart();
		},
		methods: {
			initTime() {
				this.time = [
					this.$moment().subtract(31, "days"),
					this.$moment().subtract(1, "days")
				];
			},
			submit() {
				this.getList();
			},
			initChart() {
				if (!this.chart) {
					this.chart = echarts.init(this.$refs.chartDom);
				}
				this.chart.setOption(this.option);
				this.chart.resize();
				window.onresize = () => {
					this.chart.resize();
				};
			},
			getList() {
				if (this.time.length !== 2) {
					this.$message.info("时间选择不完整");
					return;
				}
				this.$axios
					.post("/data/statistics/PV-UV", {
						endTime: this.$moment(this.time[1]).format(
							"YYYY-MM-DD"
						),
						startTime: this.$moment(this.time[0]).format(
							"YYYY-MM-DD"
						)
					})
					.then(list => {
						let uv = { name: "uv", type: "line", data: [] };
						let pv = { name: "pv", type: "line", data: [] };
						this.option.xAxis.data = [];
						for (let item of list) {
							uv.data.push(item.uv);
							pv.data.push(item.pv);
							this.option.xAxis.data.push(item.date);
						}
						this.option.series = [uv, pv];
						this.initChart();
					});
			}
		}
	};
</script>

<style lang="scss">
	.dataTotal {
		.chart {
			width: 100%;
			height: 400px;
			margin-top: 48px;
		}
	}
</style>
